import * as React from 'react'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'

const SellCTA = () => {
  return (
    <section id="sellCTA" className="py-3 py-sm-3 py-lg-4 py-xl-5 text-center bg-warning">
      <Container className="d-flex flex-column align-items-center">
        <h2 className="mb-1">Want Meati?</h2>
        <p className="lead mb-1">Get Meati on your shelves and menus today.</p>
        <Button
          variant="primary"
          className="btn-lg"
          href="#sellForm"
          data-ad-element-type="sell CTA"
          data-ad-text="Partner with us"
          data-ad-button-location="body"
          data-ad-button-id="version 1"
        >
          Partner with us
        </Button>
      </Container>
    </section>
  )
}

export default SellCTA
