import React, { useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { Container, Col, Row } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import PlntMeatiLogo from '../../images/restaurants/plnt-meati-case-study-logo.svg'

const PLNTCaseStudy = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

    const sandwiches = gsap.utils.toArray('.sandwich-stack .gatsby-image-wrapper')
    const stats = gsap.utils.toArray('.case-study__stats p')

    const tl = gsap.timeline({
      defaults: {
        duration: 0.7,
        ease: 'back.out(1)',
      },
      scrollTrigger: {
        trigger: '.case-study__stats',
        start: 'top center',
      },
    })

    stats.forEach((stat, i) => {
      tl
        .fromTo(stats[i], { autoAlpha: 0, x: 150 }, { autoAlpha: 1, x: 0 })
        .fromTo(sandwiches[i], { autoAlpha: 0, scale: 0.8 }, { autoAlpha: 1, scale: 1 }, '<')
    })

    ScrollTrigger.disable()

    gsap.to(window, {
      duration: 0.35,
      scrollTo: 0,
      onComplete: () => {
        ScrollTrigger.enable()
      },
    })

    return () => {
      const triggers = ScrollTrigger.getAll()
      if (triggers) {
        triggers.forEach((trigger) => {
          trigger.kill()
        })
      }
    }
  }, [])

  const stats = [
    'meati™ Crispy Cutlet replaced existing plant-based chicken patty',
    'meati™ Crispy Cutlet saw a 24% increase in total sales of the Crispy Chk’n Sandwich since the switch',
    '<i>"It’s absolutely delicious and nutritious and we’re so happy to share it with our PLNT Burger family.”</i> <br>- Spike Mendleson, Chef Owner.',
  ]

  return (
    <section className="pt-2 pb-3 py-md-3 case study case-study--plnt bg-light">
      <Container>
        <Row>
          <Col md="8" lg="7" xl="6" className="d-flex flex-column justify-content-center">
            <img src={PlntMeatiLogo} alt="Plnt Meati Logo" className="d-block mb-1 mb-xl-2" />
            <div className="bg-primary rounded-4 p-2 text-white case-study__stats d-md-flex flex-column justify-content-around">
              {stats.map((node, index) => <p key={index} dangerouslySetInnerHTML={{ __html: node }} className="ps-1" />)}
            </div>
          </Col>
          <Col md="4" lg="5" xl="6">
            <div className="sandwich-stack stack-parent h-100 pt-12 px-2 p-lg-3">
              {[...Array(3)].map((node, i) => (
                <StaticImage
                  src="../../images/restaurants/meati-plnt-sandwich.png"
                  quality={95}
                  placeholder=""
                  alt="PLNT Burger Meati Sandwich"
                  layout="constrained"
                  className="stack-child"
                  key={i}
                  width={352}
                />
              ))}
            </div>
          </Col>
        </Row>
      </Container>

    </section>
  )
}

export default PLNTCaseStudy
