import React, { useEffect } from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Layout from '../components/layout'
import Seo from '../components/seo'

import FDSVFeatures from '../components/common/fdsv-features'
import PLNTCaseStudy from '../components/common/plnt-case-study'

import SellCTA from '../components/sections/sell-cta'
import SellForm from '../components/common/sell-form'
import IfYouBuildIt from '../components/sections/if-you-build-it'
import CutsCards from '../components/sections/cuts-cards'
import AllStarCast from '../components/sections/all-star-cast'
import ScrollingImages from '../components/sections/scrolling-images'
import CarouselTestimonials from '../components/sections/carousel-testimonials'
import Awards from '../components/sections/awards'

const SellPage = () => {
  return (
    <Layout className="sell-page">
      <Seo title="Sell Meati™" />
      <header className="bg-primary py-2">
        <Container>
          <Row className="align-items-lg-center">
            <Col md="6">
              <h1 className="fw-bolder text-white">Your Menu is Missing Something</h1>
            </Col>
            <Col md="6">
              <p className="text-white fs-4">Add deliciously juicy, ultra-tender meati™ cuts to your menu. Animal-free, super versatile, and an excellent source of complete protein without soy, gluten, or wheat. Your customers — and their tastebuds — will thank you!</p>
              <a href="#sellForm" role="button" className="btn btn-lg btn-warning">Get Meati</a>
            </Col>
          </Row>
          <FDSVFeatures />
        </Container>
      </header>
      <PLNTCaseStudy />
      <IfYouBuildIt />
      <CutsCards />
      <CarouselTestimonials header="Chefs are Obsessed" cat="chefs" />
      <Awards />
      <SellForm />
      <AllStarCast />
      <ScrollingImages />
      <SellCTA />
    </Layout>
  )
}

export default SellPage
