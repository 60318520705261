import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Bubble from '../svg-icons/bubble'

const FDSVFeatures = () => {
  /*
  useEffect(() => {

    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    gsap.to(window, { duration: 0.35, scrollTo: 0 });

    const blocks = gsap.utils.toArray(".fdsv-feature"),
      textblocks = gsap.utils.toArray(".fdsv-feature-text");

    let mm = gsap.matchMedia();
    //For smaller than tablet, trigger animation on each block's position
    mm.add("(max-width: 767px)", () => {

      blocks.forEach((block, i) => {
        let tl = gsap.timeline({
          scrollTrigger: {
            start: "top center",
            trigger: block
          }
        })
        tl
          .to(textblocks[i], { autoAlpha: 1, rotationY: 0, duration: 1, ease: "back.out(1.7)" }, ">+0.5")
      })
    });

    //For tablet and larger, trigger animation on container block
    mm.add("(min-width: 768px)", () => {
      let tl = gsap.timeline({
        scrollTrigger: {
          start: "top bottom-=100",
          trigger: ".fdsv-features"
        }
      })
      blocks.forEach((block, i) => {
        tl
          .to(textblocks[i], { autoAlpha: 1, rotationY: 0, duration: 1, ease: "back.out(1.7)" }, ">+0.5")
      })
    });

    return () => {
      // We kill all scroll triggers so the next pages can recreate them.
      const triggers = ScrollTrigger.getAll();
      if (triggers) {
        triggers.forEach((trigger) => {
          trigger.kill();
        })
      }
    };

  }, [])
  */

  const features = useStaticQuery(graphql`
    query {
      allFdsvFeaturesYaml {
        nodes {
          title
          description
          thumb {
            childImageSharp {
              gatsbyImageData(
                width: 440
                placeholder: BLURRED
                formats: NO_CHANGE
                layout: CONSTRAINED
              )
            }
          }       
        }
      }
    }
  `)

  return (
    <Row className="fdsv-features pt-2 pt-md-4">
      {
        features.allFdsvFeaturesYaml.nodes.map((node, index) => {
          const image = getImage(node.thumb)
          const alt = `Meati ${node.title} thumbnail`
          const wrapperClass = 'rounded-4'
          const imgClass = 'rounded-4'
          return (
            <Col key={`feature-${index}`} md="4" className="my-2 my-md-0">
              <div className="rounded-4 h-100 position-relative stack-parent fdsv-feature ">
                <GatsbyImage
                  image={image}
                  alt={alt}
                  className={`stack-child ${wrapperClass}`}
                  imgClassName={imgClass}
                />
                <div className="stack-child fdsv-feature-text p-2 text-center">
                  <div className="d-flex align-items-center">
                    <p className="mb-0 fs-5 p-1 rounded text-primary">{node.description}</p>
                  </div>
                </div>
                <Bubble type="fdsv" text={node.title} />
              </div>
            </Col>
          )
        })
      }
    </Row>
  )
}

export default FDSVFeatures
